import React, { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Fab from '@mui/material/Fab';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { fetchProducts } from '../utils/dataFetcher';
import SEO from './SEO';

const Home = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const productsPerPage = 12;

  const loadProducts = useCallback(async (pageNumber) => {
    setLoading(true);
    try {
      const newProducts = await fetchProducts(pageNumber, productsPerPage);
      if (newProducts.length === 0) {
        setHasMore(false);
      } else {
        setAllProducts(prevProducts => {
          const productSet = new Set(prevProducts.map(p => p.id));
          const uniqueNewProducts = newProducts.filter(p => !productSet.has(p.id));
          return [...prevProducts, ...uniqueNewProducts];
        });
      }
    } catch (error) {
      console.error('Error loading products:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [productsPerPage]);

  useEffect(() => {
    loadProducts(page);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page, loadProducts]);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadMore = () => {
    if (!loading && hasMore) {
     
      setPage(prevPage => prevPage + 1);

    }
  };

  const ProductBox = ({ product }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <div
        ref={ref}
        className={`col-6 col-md-4 col-lg-3 mb-4 animate-on-scroll ${inView ? 'is-visible' : ''}`}
      >
        <Link to={`/product/${product.id}`} className="product-link">
          <div className="card product-card">
            <div className="product-image-container">
              <img src={product.imageUrls[0]} className="card-img-top product-image" alt={product.name} />
            </div>
            <div className="card-body">
              <h5 className="card-title product-name">{product.name}</h5>
              <p className="card-text"><strong>Price: BDT {product.price}</strong></p>
              <p className="card-text"><small className="text--muted">Category: {product.category}</small></p>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const ProductSkeleton = () => (
    <div className="col-6 col-md-4 col-lg-3 mb-4">
      <div className="card product-card">
        <Skeleton height={200} />
        <div className="card-body">
          <Skeleton count={3} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mt-5">
      <SEO />
      <h1 className="mb-4">Our Products</h1>
      <InfiniteScroll
        dataLength={allProducts.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<div className="row">{[...Array(4)].map((_, i) => <ProductSkeleton key={i} />)}</div>}
      >
        <div className="row">
          {allProducts.map((product) => (
            <ProductBox key={product.id} product={product} />
          ))}
        </div>
      </InfiniteScroll>
      {showScrollTop && (
        <Fab color="primary" size="small" onClick={scrollToTop} style={{ position: 'fixed', bottom: 16, left: 16 }}>
          <KeyboardDoubleArrowUpIcon />
        </Fab>
      )}
    </div>
  );
};

export default Home;