
export const DATA_SOURCES = {
  json: [
   
  '/data/export.json',
  '/data/crc.json',
  '/data/loc.json'
  ],
  csv: [
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQap37bJKprSawx2Y7c_zjOm2GK1zSfuAzsY0W1IarYtNpxsuutSAjshki3q8Nj0kEj4JS2nGe80qj6/pub?gid=0&single=true&output=csv"
    
  ]
};



