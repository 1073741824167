import React, { useState, useEffect, /*useCallback*/ } from 'react';
import { useParams } from 'react-router-dom';
//import Papa from 'papaparse';
import { fetchProductById } from '../utils/dataFetcherpro';
//import { SHEET_URLS } from '../config';
import ProductSEO from './ProductSEO.js';

  const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { id } = useParams();

 useEffect(() => {
    fetchProductById(id).then(fetchedProduct => {
      setProduct(fetchedProduct);
      setLoading(false);
    });
  }, [id]);


  if (loading) {
    return <div className="container mt-5">Loading product details...</div>;
  }

  if (!product) {
    return <div className="container mt-5">Product not found</div>;
  }

  return (
    <div className="container mt-5">
      <ProductSEO product={product} />

      <div className="row">
        <div className="col-md-6">
          <div className="product-image-container">
            <img 
              src={product.imageUrls[currentImageIndex]} 
              alt={`${product.name} - ${currentImageIndex + 1}`} 
              className="img-fluid product-image"
            />
          </div>
          <div className="mt-3 d-flex justify-content-center">
            {product.imageUrls.map((url, index) => (
              <img 
                key={index}
                src={url} 
                alt={`${product.name} thumbnail ${index + 1}`}
                className={`thumbnail-image ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => setCurrentImageIndex(index)}
              />
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <h1>{product.name}</h1>
          <p className="text--muted">Category: {product.category}</p>
          <p>{product.description}</p>
          <h3>Price: BDT {product.price}</h3>
          <button className="btn btn-primary mt-3">Add to Cart</button>

          <br></br>
          
        </div>
        <p>We're thrilled to announce that <h6>{product.name}</h6>  is now available in  Bangladesh! 🎉✨

            Don't miss out on this fantastic opportunity to get your hands on our amazing product. Call us now or email us for more information!
               <br></br>      
              <br></br>
              📞 Call Now: +880 1938775447
                📧 Email Us: info@trustglobal.com.bd
                <br></br>
                 <br></br>
               </p>
          
      </div>
    </div>
  );
};

export default ProductDetails;