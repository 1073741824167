import React from 'react';
import { Helmet } from 'react-helmet';

const ProductSEO = ({ product }) => (
    <Helmet>
        {/* Title Tag */}
        <title>{`${product.name} - Trust Global`}</title>

        {/* Meta Description */}
        <meta name="description" content={product.description} />

        {/* Keywords */}
        <meta name="keywords" content={product.keywords.join(', ')} />

        {/* Open Graph Meta Tags for Social Media */}
        <meta property="og:title" content={`${product.name} - Our Store`} />
        <meta property="og:description" content={product.description} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={product.image} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${product.name} - Our Store`} />
        <meta name="twitter:description" content={product.description} />
        <meta name="twitter:image" content={product.image} />
        {/* Favicon */}
        <link rel="icon" href="https://www.trustglobal.com.bd/favicon.ico" type="image/x-icon" />

        {/* Canonical URL */}
        <link rel="canonical" href={window.location.href} />
        
        {/* Google Analytics (Example) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-CHPRRG086V"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-CHPRRG086V');
          `}
        </script>
       <script>
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "nhegnrn92a");
        `}
      </script>
    </Helmet>
);

export default ProductSEO;
