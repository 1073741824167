import React from 'react';

const About = () => {
  return (
    <div className="container mt-5">
      <h1>About Us</h1>
      <p>Welcome to our e-commerce store! We are dedicated to providing high-quality products and excellent customer service.</p>
      <p>Our mission is to make online shopping easy, enjoyable, and accessible to everyone. We offer a wide range of products to suit all your needs.</p>
      <p>If you have any questions or feedback, please don't hesitate to contact us. We're always here to help!</p>
    </div>
  );
};

export default About;