import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import DarkModeToggle from './DarkModeToggle';

const Header = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${encodeURIComponent(query.trim())}`);
    }
  };

  return (
    <header className="site-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3">
            <Link to="/" className="logo-link">
              <img src={logo} alt="Trust Global Logo" className="logo" />
            </Link>
          </div>
          <div className="col-md-9">
            <div className="search-container">
              <form onSubmit={handleSearch} className="search-form">
                <div className="input-group">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="form-control"
                    placeholder="Search our site..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">Search</button>
                  </div>
                </div>
              </form>
              <DarkModeToggle />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;